const getAllAccount = () => {
    return fetch(`${process.env.REACT_APP_PORT}/account`)
        .then(response => {
            return response.json()
        })
        .then(res => {
            return res.data
        })
}

export {getAllAccount}