import Select from 'react-select';
import Loader from '../../components/Loader';
import sync from '../../asset/sync.svg';
import search from '../../asset/search.svg';
import Breadcrumb from '../../components/Breadcrumb';
import dataFilter from '../../data/filter';
// import dataSort from '../../data/sort';
import { useState } from 'react';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from "react-toastify";
import { getAlert } from '../../controller/alerts/getAlert';
import { syncAlert } from '../../controller/alerts/syncAlert';
import { restartAlert } from '../../controller/alerts/restartAlert';
import { stopAlert } from '../../controller/alerts/stopAlert';
import { createAlert } from '../../controller/alerts/createAlert';
import { useRef } from 'react';
import Pagination from '../../components/Pagination';
// import dataTimeframe from '../../data/timeframe';
import RowPairs from '../../components/RowPairs';
// import { syncWatcherHistories } from '../../controller/watcherHistories/syncWatcherHistories';
import TVUsernameSelect from '../../components/TVUsernameSelect';
import { syncWithWatcher } from '../../controller/alerts/syncWithWatcher';
import setToastMessage from '../../utils/setToastMessage';
import notifyToastSync from '../../utils/notifyToastSync';

const Alert = ({cookies}) => {
    const {pageNumber} = useParams()
    const currPage = +pageNumber
    const [filterState, setFilterState] = useState({type_coin: '', position: 'all', timeframe: ''})
    const [selectPosition, setSelectPosition] = useState(dataFilter[0])
    const [selectTimeframe, setSelectTimeframe] = useState('')
    const [filteredData, setFilteredData] = useState([])
    const [sort, setSort] = useState('')
    const [ids, setIds] = useState([])
    const [dataAlert, setDataAlert] = useState([])
    const [isLoad, setIsLoad] = useState(true)
    const [fileAlert, setFileAlert] = useState('')
    const [tempPages, setTempPages] = useState([])
    const [listPages, setListPages] = useState([])
    const [pageAttr, setPageAttr] = useState({startPage: 1, limitPage: 5, endPage: 1})
    const [tvUsername, setTvUsername] = useState('')
    const createEl = useRef()
    const selectAll = useRef()
    const {startPage, limitPage, endPage} = pageAttr
    const {type_coin, position, timeframe} = filterState

    const propsPage = {
        startPage,
        endPage,
        listPages,
        setListPages,
        setTempPages,
        mainPath: 'alert'
    }

    const handleSearch = (e) => {
        setFilterState(preValue => {
            return {
                ...preValue,
                [e.target.name]: e.target.value.toLowerCase()
            }
        })
    }

    const handleFilter = (selectedState, action) => {
        setFilterState(preValue => {
            return {
                ...preValue,
                [action.name]: selectedState.value
            }
        })
        // const position = action.name === 'position' ? selectedState.value : ''
        // handleFilterCondition(dataAlert, type_coin, position)
    }

    const handleSubmitFilter = () => {
        handleFilterCondition(dataAlert, type_coin, position, timeframe)
    }

    const handleSelectFilter = () => {
        setSelectPosition(dataFilter.find(item => item.value === position))
        // setSelectTimeframe(dataTimeframe.find(item => item.value === timeframe))
    }

    const handleSort = (selectedState, action) => {
        setSort(selectedState.value)
    }

    const handleFilterCondition = (results, type_coin, position, timeframe) => {
        if(type_coin && position && timeframe) {
            if(position === 'all') {
                const filtering = results.filter(item => {
                    const coin = item.name.toLowerCase()
                    return coin.includes(type_coin) && item.timeframe === timeframe
                })
                setFilteredData(filtering)
            } else {
                const filtering = results.filter(item => {
                    const coin = item.name.toLowerCase()
                    const direction = item.direction.toLowerCase()
                    return coin.includes(type_coin) && direction === position && item.timeframe === timeframe
                })
                setFilteredData(filtering)
            }
        } else if(type_coin && position) {
            const filtering = results.filter(item => {
                const coin = item.name.toLowerCase()
                const direction = item.direction.toLowerCase()
                if(position === 'all') {
                    return coin.includes(type_coin)
                } else {
                    return coin.includes(type_coin) && direction === position
                }
            })
            setFilteredData(filtering)
        } else if(type_coin && timeframe) {
            const filtering = results.filter(item => {
                const coin = item.name.toLowerCase()
                return coin.includes(type_coin) && item.timeframe === timeframe
            })
            setFilteredData(filtering)
        } else if(position && timeframe) {
            const filtering = results.filter(item => {
                const direction = item.direction.toLowerCase()
                if(position === 'all') {
                    return item.timeframe === timeframe
                } else {
                    return direction === position && item.timeframe === timeframe
                }
            })
            setFilteredData(filtering)
        } else if(type_coin) {
            const filtering = results.filter(item => {
                const coin = item.name.toLowerCase()
                return coin.includes(type_coin)
            })
            setFilteredData(filtering)
        } else if(position) {
            if(position === 'all') {
                setFilteredData(results)
            } else {
                const filtering = results.filter(item => {
                    const direction = item.direction.toLowerCase()
                    return direction === position
                })
                setFilteredData(filtering)
            }
        } else if(timeframe) {
            const filtering = results.filter(item => {
                return item.timeframe === timeframe
            })
            setFilteredData(filtering)
        }
    }

    // Handle Pagination
    const handlePagination = (val) => {
        if(tempPages.length) {
            setListPages(tempPages)
        } else {
            const pages = []
            const checkLimit = val > limitPage ? limitPage : val
    
            for (let i = 1; i <= checkLimit; i++) {
                pages.push(i)
            }
    
            setPageAttr(preValue => {
                return {...preValue, endPage: val}
            })
            setListPages(pages)
        }
    }

    // Sorting Data Alert Alphabetically
    const handleSortAlphabeticalAlert = (results) => {
        return results.sort((a, b) => a.name.localeCompare(b.name));
    }

    // Get Alert
    const handleDataAlert = async () => {
        setIsLoad(true)
        const {results, totalPages} = await getAlert(pageNumber, cookies.id)
        const sortedResults = handleSortAlphabeticalAlert(results)
        
        handlePagination(totalPages)
        setDataAlert(sortedResults)
        // maintain the data based on the last filter 
        handleFilterCondition(sortedResults, type_coin, position, timeframe)
        setIsLoad(false)
    }

    // Sync Alert
    const handleSyncAlert = async (createMsg = '') => {
        setIsLoad(true)
        const msg = await syncAlert(cookies.username, tvUsername)
        setToastMessage(createMsg || msg)
    } 

    // Handle sync with watchers histories
    const handleSyncWithWatcherHistories = async () => {
        setIsLoad(true)
        const msg = await syncWithWatcher(cookies.username)
        setToastMessage(msg)
    }

    // Handle change tv_username
    const handleChangeTvUsername = (selectedState) => {
        setTvUsername(selectedState.value)
    }

    // Handle change of checkbox for restart and stop alert's coin
    const handleChangeAlert = e => {
        const id = e.target.value
        if(ids.includes(id)) {
            setIds(preValue => {
                return preValue.filter(item => item !== id)
            })
        } else {
            setIds(preValue => {
                return [...preValue, id]
            })
        }
    }

    const HandleChangeAllAlert = e => {
        const alerts = [...document.querySelectorAll('#alert-pair')]
        
        alerts.forEach(item => {
            if(e.target.checked === true && item.checked === false) {
                item.click()
            } else if(e.target.checked === false && item.checked === true) {
                item.click()
            }
        })
    }

    // Handle stop restart alert
    const handleStopRestartAlert = async (state) => {
        setIsLoad(true)
        if(state === 'restart') {
            const message = await restartAlert(ids, cookies.username)
            toast.success(message);
        } else {
            const message = await stopAlert(ids, cookies.username, tvUsername)
            toast.success(message);
        }
        setIds([])
        handleDataAlert()
    }

    // Handle change create alert
    const handleChangeCreateAlert = (e) => {
        const files = e.target.files[0]
        setFileAlert(files)
    }

    // Handle submit create alert
    const handleCreateAlert = async () => {
        window.scrollTo(0,0)
        setIsLoad(true)
        const data = new FormData()
        data.append('file', fileAlert)
        data.append('general_username', cookies.username)
        data.append('tv_username', tvUsername)
        
        try {
            await createAlert(data)
            handleSyncAlert('Succes create & sync alert')
        } catch (error) {
            toast.error('The request reach timeout limit')
            setIsLoad(false)
            handleRemoveState()
        }
    }

    // Handle remove unused state 
    const handleRemoveState = () => {
        setFileAlert('')
        setIds([])
        createEl.current.value = ''
    }

    useEffect(() => {
        window.scrollTo(0,0)
        notifyToastSync()
        handleDataAlert()
        handleSelectFilter()
        handleRemoveState()
    }, [pageNumber])


    return (
        <section className="alert section">
            <Breadcrumb />
            <div className="alert__sync section__sync">
                {/* <input type="text" className="section__sync-tvusername" onChange={handleTvUsername} value={tvUsername} placeholder='TV Username' required/> */}
                <TVUsernameSelect handleChangeTvUsername={handleChangeTvUsername} />
                <button className={`alert__sync-cta section__sync-cta cta ${isLoad || !tvUsername ? 'disable' : ''}`} type='button' onClick={handleSyncAlert} disabled={isLoad || !tvUsername ? true : ''}>
                    <img src={sync} alt="" /> Sync Alert
                </button>
            </div>
            {isLoad ? 
                <Loader /> : 
                <div className="alert__container section__container">
                    <div className="alert__header section__header">
                        <h1 className="alert__title section__title">Alert List</h1>
                        <div className="alert__filter-box">
                            <div className="alert__input-box">
                                <img src={search} alt="" />
                                <input type="text" name='type_coin' placeholder='Type' onChange={handleSearch} value={type_coin} />
                            </div>
                            {/* <Select
                                onChange={handleFilter}
                                options={dataTimeframe}
                                className={'select-input alert__input-timeframe'}
                                placeholder='Timeframe'
                                defaultValue={selectTimeframe}
                                name='timeframe'
                                theme={(theme) => ({
                                    ...theme,
                                    colors: {
                                    ...theme.colors,
                                    primary25: '#1F2733'
                                    }
                                })}
                            /> */}
                            <Select
                                onChange={handleFilter}
                                options={dataFilter}
                                className={'select-input alert__input-filter'}
                                placeholder='Position'
                                defaultValue={selectPosition}
                                name='position'
                                theme={(theme) => ({
                                    ...theme,
                                    colors: {
                                    ...theme.colors,
                                    primary25: '#1F2733'
                                    }
                                })}
                            />
                            <button className="alert__search cta" onClick={handleSubmitFilter}>Search</button>
                        </div>
                        <button className='alert__sync-cta alert__sync-history-cta section__sync-cta cta' type='button' onClick={handleSyncWithWatcherHistories}>
                            <img src={sync} alt="" /> Sync with Watchers Histories
                        </button>
                    </div>
                    {/* <div className="alert__sort">
                        <span>Sort by:</span>
                        <Select
                            onChange={handleSort}
                            options={dataSort}
                            className={'select-input alert__input-sort'}
                            placeholder='Sort'
                            defaultValue={dataSort[0]}
                            name='sort'
                            theme={(theme) => ({
                                ...theme,
                                colors: {
                                ...theme.colors,
                                primary25: '#1F2733'
                                }
                            })}
                        />
                    </div> */}
                    <table className="alert__table">
                        <thead>
                            <tr>
                                <th>Pair</th>
                                <th>Position</th>
                                <th>Timeframe</th>
                                <th>Active</th>
                                <th>Status</th>
                                <th>Last Updated</th>
                                <th>
                                    <input ref={selectAll} type="checkbox" className='coin-check' name='selectAll' id="allAlert" onChange={HandleChangeAllAlert} disabled={!filteredData.length ? true : ''} />
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                filteredData.map((item, i) => {
                                    return (
                                        <RowPairs key={i} id='alert-pair' data={item} handleChangePair={handleChangeAlert} />
                                    )
                                })
                            }
                        </tbody>
                    </table>

                    {filteredData.length === 0 && <h1 className='not-found'>Pairs are not found.</h1>}

                    <div className="alert__action-box">
                        <div className="action__restart-stop">
                            <button type="button" className={`action__restart-btn ${!ids.length ? 'disable' : ''}`} disabled={!ids.length ? true : ''} onClick={() => handleStopRestartAlert('restart')}>Start Alert</button>
                            <button type="button" className={`action__stop-btn ${!ids.length ? 'disable' : ''}`} disabled={!ids.length ? true : ''} onClick={() => handleStopRestartAlert('stop')}>Stop Alert</button>
                        </div>
                    </div>
                </div>
            }
            <div className={`action__create ${isLoad && 'hide'}`}>
                <div className="action__create-input-box">
                    <input type="file" name='tv_file' id='tv_file' ref={createEl} className='action__create-input' hidden onChange={handleChangeCreateAlert} />
                    <span className='action__create-filename'>{fileAlert.name}</span>
                    <label htmlFor="tv_file" className='action__create-input'>Upload File</label>
                </div>
                <button type="button" className={`action__create-btn ${tvUsername && fileAlert ? '' : 'disable'}`} disabled={tvUsername && fileAlert ? '' : true} onClick={handleCreateAlert} >Create Alert</button>
            </div>
            <div className="watcher__bottom show-redirect">
                {!isLoad && <a href="/alert/all" className="alert__link-all show-redirect__link">Show All Pairs</a>}
                {!isLoad && <Pagination {...propsPage} data={dataAlert} />}
            </div>
        </section>
    )
}

export default Alert
