import React from "react"
import Select from 'react-select';
import { useState } from "react";
import { useEffect } from "react"
import { getAllAccount } from '../controller/tradingview/getAllAccount';

const TVUsernameSelect = ({handleChangeTvUsername}) => {
    const [dataTVUsername, setDataTVUsername] = useState([])

    // Get TV Username based on trading view
    const handleGetTvUsername = async () => {
        const data = await getAllAccount()
        const usernames = data.map(item => {
            return {
                label: item.username,
                value: item.username
            }
        })
        setDataTVUsername(usernames)
    }

    useEffect(() => {
        handleGetTvUsername()
    }, [])


    return (
        <Select
            onChange={handleChangeTvUsername}
            options={dataTVUsername}
            className={'select-input tvusername__select'}
            placeholder='TV Username'
            name='tvUsername'
            theme={(theme) => ({
                    ...theme,
                    colors: {
                    ...theme.colors,
                    primary25: '#1F2733'
                }
            })}
        />
    )   
}

export default TVUsernameSelect