const getStatistic = (username, startDate, endDate) => {
    return fetch(`${process.env.REACT_APP_PORT}/dashboard/statistics?general_username=${username}&date_start=${startDate}&date_end=${endDate}`)
        .then(response => {
            return response.json()
        })
        .then(res => {
            return res.data.statistics
        })
}

export {getStatistic}