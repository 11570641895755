const syncWithWatcher = (username) => {
    return fetch(`${process.env.REACT_APP_PORT}/tradingview/alerts/sync-with-watchers`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json',  'Accept': 'application/json'},
            body: JSON.stringify({
                general_username: username
            })
        })
        .then(response => {
            return response.json()
        })
        .then(res => {
            return res.msg
        })
}

export {syncWithWatcher}