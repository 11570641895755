import React from "react";
import { useLocation } from "react-router-dom";

const Breadcrumb = () => {
    const location = useLocation()
    const path = location.pathname.substring(1)
    
    return (
        <div className="breadcrumb">
            <span className='breadcrumb__pathname'>{path}</span>
            <div className="breadcrumb__status-box">
                <div className="breadcrumb__dot"></div>
                <span className="breadcrumb__status">Connected</span>
            </div>
        </div>
    )
}

export default Breadcrumb