import { useEffect } from "react"
import { useState } from "react"
import { useNavigate } from "react-router-dom"
import { toast } from "react-toastify"
import account_img from '../../asset/account.svg'
import { getAllAccount } from "../../controller/account/getAllAccount"
import Loader from "../../components/Loader"

const ChooseAccount = ({setCookie, setCurrPath}) => {
    const navigate = useNavigate()
    const path = window.location.pathname
    const [isLoad, setIsLoad] = useState(true)
    const [accounts, setAccounts] = useState([])
    const [currAccount, setCurrAccount] = useState({currID: '', currUsername: ''})
    const {currID, currUsername} = currAccount

    const handleAccount = async () => {
        const data = await getAllAccount()
        setAccounts(data)
        setTimeout(() => {
            setIsLoad(false)
        }, 600)
    }

    const handleChange = (e) => {
        const [id, username] = e.target.value.split(',')
        setCurrAccount({
            currID: id,
            currUsername: username
        })
    }   

    const handleSubmit = (e) => {
        e.preventDefault()
        setCookie('id', currID, { path: '/' });
        setCookie('username', currUsername, { path: '/' });
        setCurrPath('')
        toast.success('Success logged in')
        navigate('/')
    }

    useEffect(() => {
        setCurrPath(path)
        handleAccount()
    }, [])

    return (
        <div className="choose">
            <h1 className="choose__title">Choose Your Account to Continue</h1>
            {isLoad ? <Loader state='loader-choose-account'/> :
                <form className="choose__form" onSubmit={handleSubmit}>
                    {
                        accounts.length !== 0 ? 
                        <div className="choose__box">
                            {accounts.map((data, i) => {
                                const {_id, general_username} = data
                                return (
                                    <div className="choose__list" key={i}>
                                        <input type="radio" name="choose_account" id={`choose_account-${_id+1}`} value={`${_id},${general_username}`} className="choose__radio" onChange={handleChange} hidden required />
                                        <label htmlFor={`choose_account-${_id+1}`} className="choose__label">
                                            <img src={account_img} alt="" />
                                            <h1>{general_username}</h1>
                                        </label>
                                    </div>
                                )
                            })}
                        </div> : <a href="/account/add" className="choose__btn cta">Add Account</a>
                    }
                    {accounts.length !== 0 && <div className="choose__gutter"></div>}
                    {accounts.length !== 0 && 
                    <div className="choose__btn-box">
                        <button type="submit" className={`choose__btn cta ${!currID ? 'disable' : ''}`} disabled={!currID ? true : ''}>Choose</button>
                        <span>or</span>
                        <a href="/account/add" className="choose__btn choose__btn-redirect cta">Add Account</a>
                    </div>
                    }
                </form>
            }
        </div>
    )
}

export default ChooseAccount