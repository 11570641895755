const syncWatcher = (username, tv_username) => {
    return fetch(`${process.env.REACT_APP_PORT}/tradingview/watchers/sync`, {
            method: 'POST',
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                general_username: username,
                tv_username
            })
        })
        .then(response => {
            return response.json()
        }).then(res => {
            return res.msg
        })
}

export {syncWatcher}
