import {
	BrowserRouter as Router,
	Route,
	Routes,
	Navigate,
} from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Navigation from "./components/Navigation";
// import Setting from './components/Setting';
import Watcher from "./pages/watcher/Watcher";
import Logs from "./pages/Logs";
import Alert from "./pages/alert/Alert";
import SwitchAccount from "./pages/auth/SwitchAccount";
import { useCookies } from "react-cookie";
import ChooseAccount from "./pages/auth/ChooseAccount";
import { useState } from "react";
// import { useEffect } from 'react';
import AddAccount from "./pages/auth/AddAccount";
import EditAccount from "./pages/auth/EditAccount";
import WatcherAll from "./pages/watcher/WatcherAll";
import AlertAll from "./pages/alert/AlertAll";
import Statistics from "./pages/Statistics";
import OpenTrades from "./pages/OpenTrades";
// import WatcherHistory from "./pages/WatcherHistory";
import Tradingview from "./pages/tradingview/Tradingview";
import TradingviewAdd from "./pages/tradingview/TradingviewAdd";
import TradingviewEdit from "./pages/tradingview/TradingviewEdit";

const App = () => {
	const path = window.location.pathname;
	const [cookies, setCookie, removeCookie] = useCookies(["id", "username"]);
	const [currPath, setCurrPath] = useState(path);

	return (
		<Router>
			<div
				className={`App ${
					currPath === "/account/choose" ? "App-one-col" : ""
				}`}
			>
				<ToastContainer
					position="top-right"
					autoClose={2000}
					hideProgressBar={false}
					newestOnTop={false}
					closeOnClick
					rtl={false}
					pauseOnFocusLoss
					draggable
					pauseOnHover
					theme="dark"
				/>

				{currPath !== "/account/choose" && (
					<Navigation cookies={cookies} removeCookie={removeCookie} />
				)}
				<div className="content">
					<Routes>
						<Route
							path="/"
							element={
								<Navigate
									to={`${
										cookies.username
											? "/alert/pages/1"
											: "/account/choose"
									}`}
								/>
							}
							exact
						/>
						<Route path="watcher">
							<Route
								index
								element={<Navigate to="/watcher/pages/1" />}
							/>
							<Route
								path="all"
								element={
									cookies.username ? (
										<WatcherAll cookies={cookies} />
									) : (
										<Navigate to="/account/choose" />
									)
								}
							/>
							<Route
								path="pages/:pageNumber"
								element={
									cookies.username ? (
										<Watcher cookies={cookies} />
									) : (
										<Navigate to="/account/choose" />
									)
								}
							/>
						</Route>
						{/* <Route path='signaldirection' element={cookies.username ? <WatcherHistory cookies={cookies} /> : <Navigate to='/account/choose' />}  /> */}
						<Route path="alert">
							<Route
								index
								element={<Navigate to="/alert/pages/1" />}
							/>
							<Route
								path="all"
								element={
									cookies.username ? (
										<AlertAll cookies={cookies} />
									) : (
										<Navigate to="/account/choose" />
									)
								}
							/>
							<Route
								path="pages/:pageNumber"
								element={
									cookies.username ? (
										<Alert cookies={cookies} />
									) : (
										<Navigate to="/account/choose" />
									)
								}
							/>
						</Route>
						<Route path="account">
							<Route
								index
								element={<Navigate to="/account/choose" />}
							/>
							<Route path="add" element={<AddAccount />} />
							<Route
								path="edit/:id"
								element={
									<EditAccount
										cookies={cookies}
										setCookie={setCookie}
									/>
								}
							/>
							<Route
								path="choose"
								element={
									<ChooseAccount
										setCookie={setCookie}
										setCurrPath={setCurrPath}
									/>
								}
							/>
							<Route
								path="switch"
								element={
									cookies.username ? (
										<SwitchAccount
											cookies={cookies}
											setCookie={setCookie}
											removeCookie={removeCookie}
										/>
									) : (
										<Navigate to="/account/choose" />
									)
								}
							/>
						</Route>
						<Route path="tradingview">
							<Route
								index
								element={
									cookies.username ? (
										<Tradingview />
									) : (
										<Navigate to="/account/choose" />
									)
								}
							/>
							<Route
								path="add"
								element={
									cookies.username ? (
										<TradingviewAdd />
									) : (
										<Navigate to="/account/choose" />
									)
								}
							/>
							<Route
								path="edit/:id"
								element={
									cookies.username ? (
										<TradingviewEdit />
									) : (
										<Navigate to="/account/choose" />
									)
								}
							/>
						</Route>
						<Route path="logs">
							<Route
								index
								element={<Navigate to="/logs/pages/1" />}
							/>
							<Route
								path="pages/:pageNumber"
								element={
									cookies.username ? (
										<Logs cookies={cookies} />
									) : (
										<Navigate to="/account/choose" />
									)
								}
							/>
						</Route>
						<Route
							path="statistics"
							element={
								cookies.username ? (
									<Statistics cookies={cookies} />
								) : (
									<Navigate to="/account/choose" />
								)
							}
						/>
						<Route
							path="opentrades"
							element={
								cookies.username ? (
									<OpenTrades cookies={cookies} />
								) : (
									<Navigate to="/account/choose" />
								)
							}
						/>
						{/* <Route path='setting' element={cookies.username ? <Setting /> : <Navigate to='/account/choose' />}  /> */}
						{/* <Route path='switch-account' element={cookies.username ? <SwitchAccount cookies={cookies} setCookie={setCookie} /> : <Navigate to='/choose-account' />}  />
              <Route path='choose-account' element={<ChooseAccount setCookie={setCookie} setCurrPath={setCurrPath} />}  /> */}
					</Routes>
				</div>
			</div>
		</Router>
	);
};

export default App;
