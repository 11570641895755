
const ModalDelete = ({isPopUp, setIsPopUp, username, handleDeleteAccount}) => {

    const closeModal = () => {
        setIsPopUp(false)
    }

    return (
        <div className={`modal ${isPopUp && 'modal-popup'}`}>
            <div className="modal__detail">
                <h1 className="modal__title">Are you sure want to delete <span>"{username}"</span> ?</h1>
                <div className="modal__btn-box">
                    <button className="modal__cancel modal__btn cta" onClick={closeModal}>Cancel</button>
                    <button className="modal__delete modal__btn cta" onClick={handleDeleteAccount}>Delete</button>
                </div>
            </div>
        </div>
    )
}

export default ModalDelete