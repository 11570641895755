import { NavLink } from "react-router-dom"
import leftArr from '../asset/left-arr.svg';
import rightArr from '../asset/right-arr.svg';

const Pagination = (props) => {
    const {data, startPage, endPage, listPages, setListPages, setTempPages, mainPath} = props

    const handlePrevPage = () => {
        if(listPages.at(0) !== startPage) {
            const prevPage = listPages.slice(0, listPages.length-1)
            prevPage.unshift(listPages.at(0) - 1)
            setListPages(prevPage)
            setTempPages(prevPage)
        }
    }

    const handleNextPage = () => {
        if(listPages.at(-1) !== endPage) {
            const nextPage = listPages.slice(1).concat(listPages.at(-1) + 1)
            setListPages(nextPage)
            setTempPages(nextPage)
        }
    }

    return (
        <div className={`pagination ${data.length === 0 ? 'hide' : ''}`}>
            <button type='button' className={`pagination__btn ${listPages.includes(startPage) ? 'disable' : ''}`} disabled={listPages.includes(startPage) ? true : ''} onClick={handlePrevPage}><img src={leftArr} alt="" /></button>
                {listPages.map((item, i) => {
                    return (
                        <NavLink key={i} to={`/${mainPath}/pages/${item}`} activeclassname="active" className="pagination__link" exact>
                            {item}
                        </NavLink>
                    )
                })}
            <button type='button' className={`pagination__btn ${listPages.includes(endPage) ? 'disable' : ''}`} disabled={listPages.includes(endPage) ? true : ''} onClick={handleNextPage}><img src={rightArr} alt="" /></button>
        </div>
    )
}

export default Pagination