import { cryptoIcon } from "../data/cryptoIcon"
import moment from 'moment';
import 'moment-timezone';

const RowLogs = ({data}) => {
    const {condition, profit, percent, pair, direction, close_reason, open_timestamp, close_timestamp} = data
    const convertTime = (time) => {
        return moment.tz(time*1000, 'Asia/Jakarta').format('DD-MM-YYYY HH:mm:ss')
    }

    const convertRuntime = () => {
        const a = moment(open_timestamp*1000)
        const b = moment(close_timestamp*1000)

        const minute = b.diff(a, 'minutes')
        const hour = (minute/60).toFixed(1)
        return `${hour} hours`
    }

    return (
        <>
            <tr>
                <td className='log__coin'><img src={cryptoIcon(pair)} alt="crypto coin" loading='lazy' />{pair}</td>
                <td className={`log__direction log-${direction.toLowerCase() === 'long' ? 'green' : 'red'}`}>{direction}</td>
                {/* <td>{timeframe}</td> */}
                <td className={`log__profit log-${profit.includes('-') ? 'red' : 'green'}`}>{profit}</td>
                <td className="log__reason">{close_reason}</td>
                <td>{convertTime(open_timestamp)}</td>
                <td>{convertTime(close_timestamp)}</td>
                <td>{convertRuntime()}</td>
                <td>x20</td>
                <td className={`log__condition log-${condition.toLowerCase() === 'profit' ? 'green' : 'red'}`}>{condition}</td>
                {/* <td className='watcher__action'>
                <button type='button' className="watcher__cta watcher__cta-active">Active</button>
                <button type='button' className="watcher__cta watcher__cta-delete">Delete</button>
                </td> */}
            </tr>
        </>
    )
}

export default RowLogs