import React from "react";
import Lottie from 'lottie-react';
import loading from '../asset/loader.json';

const Loader = ({state}) => {
    return (
        <div className={`loader ${state}`}>
            <div className="lottie">
                <Lottie 
                    animationData={loading}
                    loop={true}
                    autoPlay={true}
                    height={'100%'}
                    width={'100%'}
                />
            </div>
        </div>
    )
}

export default Loader