const getAllWatcher = (idAccount) => {
    return fetch(`${process.env.REACT_APP_PORT}/tradingview/watchers?populate=account&account=${idAccount}&all=1`)
        .then(response => {
            return response.json()
        })
        .then(res => {
            return res.data
        })
}

export {getAllWatcher}