import { cryptoIcon } from "../data/cryptoIcon"
import arr_up from '../asset/arr-up.svg'
import arr_down from '../asset/arr-down.svg'

const RowOpenTrades = ({data}) => {
    const {symbol, positionSide, markPrice, unRealizedProfit, liquidationPrice, leverage, margin} = data

    return (
        <>
            <tr>
                <td>
                    <img className="trades__arrow" src={positionSide === 'LONG' ? arr_up : arr_down} alt="" />
                </td>
                <td className="trades__pair">
                    <img className="trades__coin" src={cryptoIcon(symbol)} alt="crypto coin" />{symbol}
                </td>
                <td>{markPrice}</td>
                <td>{unRealizedProfit}</td>
                <td>{liquidationPrice}</td>
                <td>x{leverage}</td>
                <td>{margin}</td>
                {/* <td className='watcher__action'>
                <button type='button' className="watcher__cta watcher__cta-active">Active</button>
                <button type='button' className="watcher__cta watcher__cta-delete">Delete</button>
                </td> */}
            </tr>
        </>
    )
}

export default RowOpenTrades