import Select from 'react-select';
import sync from '../../asset/sync.svg';
import searchIcon from '../../asset/search.svg';
import Breadcrumb from '../../components/Breadcrumb';
import dataFilter from '../../data/filter';
// import dataSort from '../../data/sort';
import { useState } from 'react';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Loader from '../../components/Loader';
import { getWatcher } from '../../controller/watchers/getWatcher';
import { syncWatcher } from '../../controller/watchers/syncWatcher';
import { toast } from "react-toastify";
import { stopWatcher } from '../../controller/watchers/stopWatcher';
import { restartWatcher } from '../../controller/watchers/restartWatcher';
import { createWatcher } from '../../controller/watchers/createWatcher';
import { useRef } from 'react';
import Pagination from '../../components/Pagination';
// import dataTimeframe from '../../data/timeframe';
import RowPairs from '../../components/RowPairs';
import TVUsernameSelect from '../../components/TVUsernameSelect';
import notifyToastSync from '../../utils/notifyToastSync';
import setToastMessage from '../../utils/setToastMessage';

const Watcher = ({cookies}) => {
    const {pageNumber} = useParams()
    const currPage = +pageNumber
    const [filterState, setFilterState] = useState({type_coin: '', position: 'all', timeframe: ''})
    const [selectPosition, setSelectPosition] = useState(dataFilter[0])
    const [selectTimeframe, setSelectTimeframe] = useState('')
    const [filteredData, setFilteredData] = useState([])
    const [sort, setSort] = useState('')
    const [ids, setIds] = useState([''])
    const [dataWatcher, setDataWatcher] = useState([])
    const [isLoad, setIsLoad] = useState(true)
    const [fileWatcher, setFileWatcher] = useState('')
    const [tempPages, setTempPages] = useState([])
    const [listPages, setListPages] = useState([])
    const [pageAttr, setPageAttr] = useState({startPage: 1, limitPage: 5, endPage: 1})
    const [tvUsername, setTvUsername] = useState('')
    const createEl = useRef()
    const selectAll = useRef()
    const {startPage, limitPage, endPage} = pageAttr
    const {type_coin, position, timeframe} = filterState

    const propsPage = {
        startPage,
        endPage,
        listPages,
        setListPages,
        setTempPages,
        mainPath: 'watcher'
    }

    const handleSearch = (e) => {
        setFilterState(preValue => {
            return {
                ...preValue,
                [e.target.name]: e.target.value
            }
        })
    }

    const handleFilter = (selectedState, action) => {
        setFilterState(preValue => {
            return {
                ...preValue,
                [action.name]: selectedState.value
            }
        })
    }

    const handleSubmitFilter = () => {
        handleFilterCondition(dataWatcher, type_coin, position, timeframe)
    }

    const handleSelectFilter = () => {
        setSelectPosition(dataFilter.find(item => item.value === position))
        // setSelectTimeframe(dataTimeframe.find(item => item.value === timeframe))
    }

    const handleSort = (selectedState, action) => {
        setSort(selectedState.value)
    }

    const handleFilterCondition = (results, type_coin, position, timeframe) => {
        if(type_coin && position && timeframe) {
            if(position === 'all') {
                const filtering = results.filter(item => {
                    const coin = item.pair.toLowerCase()
                    return coin.includes(type_coin) && item.timeframe === timeframe
                })
                setFilteredData(filtering)
            } else {
                const filtering = results.filter(item => {
                    const coin = item.pair.toLowerCase()
                    const direction = item.direction.toLowerCase()
                    return coin.includes(type_coin) && direction === position && item.timeframe === timeframe
                })
                setFilteredData(filtering)
            }
        } else if(type_coin && position) {
            const filtering = results.filter(item => {
                const coin = item.pair.toLowerCase()
                const direction = item.direction.toLowerCase()
                if(position === 'all') {
                    return coin.includes(type_coin)
                } else {
                    return coin.includes(type_coin) && direction === position
                }
            })
            setFilteredData(filtering)
        } else if(type_coin && timeframe) {
            const filtering = results.filter(item => {
                const coin = item.pair.toLowerCase()
                return coin.includes(type_coin) && item.timeframe === timeframe
            })
            setFilteredData(filtering)
        } else if(position && timeframe) {
            const filtering = results.filter(item => {
                const direction = item.direction.toLowerCase()
                if(position === 'all') {
                    return item.timeframe === timeframe
                } else {
                    return direction === position && item.timeframe === timeframe
                }
            })
            setFilteredData(filtering)
        } else if(type_coin) {
            const filtering = results.filter(item => {
                const coin = item.pair.toLowerCase()
                return coin.includes(type_coin)
            })
            setFilteredData(filtering)
        } else if(position) {
            if(position === 'all') {
                setFilteredData(results)
            } else {
                const filtering = results.filter(item => {
                    const direction = item.direction.toLowerCase()
                    return direction === position
                })
                setFilteredData(filtering)
            }
        } else if(timeframe) {
            const filtering = results.filter(item => {
                return item.timeframe === timeframe
            })
            setFilteredData(filtering)
        }
    }

    const handlePagination = (val) => {
        if(tempPages.length) {
            setListPages(tempPages)
        } else {
            const pages = []
            const checkLimit = val > limitPage ? limitPage : val
    
            for (let i = 1; i <= checkLimit; i++) {
                pages.push(i)
            }
    
            setPageAttr(preValue => {
                return {...preValue, endPage: val}
            })
            setListPages(pages)
        }
    }

     // Sync Watchers
     const handleSyncWatcher = async (createMsg = '') => {
        setIsLoad(true)
        const msg = await syncWatcher(cookies.username, tvUsername)
        setToastMessage(createMsg || msg)
    } 

    // Sorting Data Watcher Alphabetically
    const handleSortAlphabeticalWatcher = (results) => {
        return results.sort((a, b) => a.pair.localeCompare(b.pair));
    }

    // Get Watcher
    const handleDataWatcher = async () => {
        setIsLoad(true)
        const {results, totalPages} = await getWatcher(pageNumber, cookies.id)
        const sortedResults = handleSortAlphabeticalWatcher(results)

        handlePagination(totalPages)
        setDataWatcher(sortedResults)
        // maintain the data based on the last filter 
        handleFilterCondition(sortedResults, type_coin, position, timeframe)
        setIsLoad(false)
    }

    // Handle change tv_username
    const handleChangeTvUsername = (selectedState) => {
        setTvUsername(selectedState.value)
        console.log(selectedState);
    }

    // Handle change of checkbox for restart and stop watcher's coin
    const handleChangeWatcher = e => {
        const id = e.target.value
        if(ids.includes(id)) {
            setIds(preValue => {
                return preValue.filter(item => item !== id)
            })
        } else {
            setIds(preValue => {
                return [...preValue, id]
            })
        }
    }

    const HandleChangeAllWatcher = e => {
        const watchers = [...document.querySelectorAll('#watcher-pair')]
        
        watchers.forEach(item => {
            if(e.target.checked === true && item.checked === false) {
                item.click()
            } else if(e.target.checked === false && item.checked === true) {
                item.click()
            }
        })
    }

    // Handle stop restart watcher
    const handleStopRestartWatcher = async (state) => {
        setIsLoad(true)
        if(state === 'restart') {
            const message = await restartWatcher(ids, cookies.username)
            toast.success(message)
        } else {
            const message = await stopWatcher(ids, cookies.username)
            toast.success(message)
        }
        setIds([])
        handleDataWatcher()
        // setIsLoad(false)
    }

    // Handle change create watcher
    const handleChangeCreateWatcher = (e) => {
        const files = e.target.files[0]
        setFileWatcher(files)
    }

    // Handle submit create alert
    const handleCreateWatcher = async () => {
        window.scrollTo(0,0)
        setIsLoad(true)
        const data = new FormData()
        data.append('file', fileWatcher)
        data.append('general_username', cookies.username)
        data.append('tv_username', tvUsername)

        try {
            await createWatcher(data)
            handleSyncWatcher('Success create & sync watcher')
        } catch (error) {
            toast.error('The request reach timeout limit')
            setIsLoad(false)
            handleRemoveState()
        }
        
    }

    // Handle remove unused state
    const handleRemoveState = () => {
        setFileWatcher('')
        setIds([])
        createEl.current.value = ''
    }

    useEffect(() => {
        window.scrollTo(0,0)
        notifyToastSync()
        handleDataWatcher()
        handleSelectFilter()
        handleRemoveState()
    }, [pageNumber])

    return (
        <section className="watcher section">
            <Breadcrumb />
            <div className="watcher__sync section__sync">
                {/* <input type="text" className="section__sync-tvusername" onChange={handleTvUsername} value={tvUsername} placeholder='TV Username' required/> */}
                <TVUsernameSelect handleChangeTvUsername={handleChangeTvUsername} />
                <button className={`watcher__sync-cta section__sync-cta cta ${isLoad || !tvUsername ? 'disable' : ''}`} type='button' onClick={handleSyncWatcher} disabled={isLoad || !tvUsername ? true : ''}>
                    <img src={sync} alt="" /> Sync Watcher
                </button>
            </div>
            {isLoad ? 
                <Loader /> : 
                <div className="watcher__container section__container">
                    <div className="watcher__header section__header">
                        <h1 className="watcher__title section__title">Watcher List</h1>
                        <div className="watcher__filter-box">
                            <div className="watcher__input-box">
                                <img src={searchIcon} alt="" />
                                <input type="text" name='type_coin' placeholder='Type' onChange={handleSearch} value={type_coin} />
                            </div>
                            {/* <Select
                                onChange={handleFilter}
                                options={dataTimeframe}
                                className={'select-input watcher__input-timeframe'}
                                placeholder='Timeframe'
                                defaultValue={selectTimeframe}
                                name='timeframe'
                                theme={(theme) => ({
                                    ...theme,
                                    colors: {
                                    ...theme.colors,
                                    primary25: '#1F2733'
                                    }
                                })}
                            /> */}
                            <Select
                                onChange={handleFilter}
                                options={dataFilter}
                                className={'select-input watcher__input-filter'}
                                placeholder='Position'
                                defaultValue={selectPosition}
                                name='position'
                                theme={(theme) => ({
                                    ...theme,
                                    colors: {
                                    ...theme.colors,
                                    primary25: '#1F2733'
                                    }
                                })}
                            />
                            <button className="watcher__search cta" onClick={handleSubmitFilter}>Search</button>
                        </div>
                    </div>
                    {/* <div className="watcher__sort">
                        <span>Sort by:</span>
                        <Select
                            onChange={handleSort}
                            options={dataSort}
                            className={'select-input watcher__input-sort'}
                            placeholder='Sort'
                            defaultValue={dataSort[0]}
                            name='sort'
                            theme={(theme) => ({
                                ...theme,
                                colors: {
                                ...theme.colors,
                                primary25: '#1F2733'
                                }
                            })}
                        />
                    </div> */}
                    <table className="watcher__table">
                        <thead>
                            <tr>
                                <th>Pair</th>
                                <th>Position</th>
                                <th>Timeframe</th>
                                <th>Active</th>
                                {/* <th>
                                    <input ref={selectAll} type="checkbox" className='coin-check' name='selectAll' id="allWatcher" onChange={HandleChangeAllWatcher} disabled={!filteredData.length ? true : ''} />
                                </th> */}
                            </tr>
                        </thead>
                        <tbody>
                            {
                                filteredData.map((item, i) => {
                                    return (
                                        <RowPairs key={i} id='watcher-pair' data={item} handleChangePair={handleChangeWatcher} />
                                    )
                                })
                            }
                        </tbody>
                    </table>

                    {filteredData.length === 0 && <h1 className='not-found'>Pairs are not found.</h1>}

                    {/* <div className="watcher__action-box">
                        <div className="action__restart-stop">
                            <button type="button" className={`action__restart-btn ${!ids.length ? 'disable' : ''}`} disabled={!ids.length ? true : ''} onClick={() => handleStopRestartWatcher('restart')}>Start Watcher</button>
                            <button type="button" className={`action__stop-btn ${!ids.length ? 'disable' : ''}`} disabled={!ids.length ? true : ''} onClick={() => handleStopRestartWatcher('stop')} >Stop Watcher</button>
                        </div>
                    </div> */}
                </div>
            }
            <div className={`action__create ${isLoad && 'hide'}`}>
                <div className="action__create-input-box">
                    <input type="file" name='tv_file' id='tv_file' ref={createEl} className='action__create-input' hidden onChange={handleChangeCreateWatcher} />
                    <span className='action__create-filename'>{fileWatcher.name}</span>
                    <label htmlFor="tv_file" className='action__create-input'>Upload File</label>
                </div>
                <button type="button" className={`action__create-btn ${tvUsername && fileWatcher ? '' : 'disable'}`} disabled={tvUsername && fileWatcher ? '' : true} onClick={handleCreateWatcher} >Create Watcher</button>
            </div>
            <div className="watcher__bottom show-redirect">
                {!isLoad && <a href="/watcher/all" className="watcher__link-all show-redirect__link">Show All Pairs</a>}
                {!isLoad && <Pagination {...propsPage} data={dataWatcher} />}
            </div>
        </section>
    )
}

export default Watcher
