import { useState } from "react";
import { createAccount } from "../../controller/account/createAccount";
import { toast } from "react-toastify";
import Breadcrumb from "../../components/Breadcrumb";
import Loader from "../../components/Loader";
import { useNavigate } from "react-router-dom";
import FormAccount from "../../components/UI/FormAccount";

const AddAccount = () => {
    const navigate = useNavigate()
    const [isLoad, setIsLoad] = useState(false)
    const [accountData, setAccountData] = useState({
        general_username: '',
        cx_email: '',
        cx_password: '',
        cx_auth_token: '',
        cx_refresh_token: '',
        gmail_refresh_token: '',
        tf_watcher_follow: '',
        tf_alert_follow: '',
        tg_watcher_chat_id: '',
        tg_alert_chat_id: '',
        bnc_api_key: '',
        bnc_private_key: '',
        proxy: ''
    })

    const handleChange = (e) => {
        setAccountData(preValue => {
            return {
                ...preValue,
                [e.target.name]: e.target.value
            }
        })
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        setIsLoad(true)
        const message = await createAccount(accountData)
        toast.success(message)
        navigate('/account/switch')
    }


    return (
        <div className="account add">
            <Breadcrumb />
            <h1>Add Account</h1>
            {isLoad ? <Loader /> : 
            <FormAccount handleChange={handleChange} handleSubmit={handleSubmit} buttontext='Create' data={accountData} />}
        </div>
    )
}

export default AddAccount