import React from "react";
import { useNavigate } from "react-router-dom";

const FormAccount = ({handleChange, handleSubmit, buttontext, data}) => {
    const navigate = useNavigate()
    const {
        general_username,
        cx_email,
        cx_password,
        cx_auth_token,
        cx_refresh_token,
        gmail_refresh_token,
        tf_alert_follow,
        tg_alert_chat_id,
        bnc_api_key,
        bnc_private_key,
        proxy
        // tf_watcher_follow,
        // tg_watcher_chat_id,
    } = data

    const handleCancel = () => {
        navigate(-1)
    }

    return (
        <div className="account-box">
            <form className="account-form" onSubmit={handleSubmit}>
                <div className="account-group">
                    <label htmlFor="general_username">General Username</label>
                    <input type="text" name="general_username" id="general_username" value={general_username} onChange={handleChange} placeholder="General Username" autoComplete="off" required />
                </div>
                <div className="account-group">
                    <label htmlFor="cx_email">CX Email</label>
                    <input type="email" name="cx_email" id="cx_email" value={cx_email} onChange={handleChange} placeholder="CX Email" autoComplete="off" required />
                </div>
                <div className="account-group">
                    <label htmlFor="cx_password">CX Password</label>
                    <input type="text" name="cx_password" id="cx_password" value={cx_password} onChange={handleChange} placeholder="CX Password" autoComplete="off" required />
                </div>
                <div className="account-group">
                    <label htmlFor="cx_auth_token">CX Auth Token</label>
                    <input type="text" name="cx_auth_token" id="cx_auth_token" value={cx_auth_token} onChange={handleChange} placeholder="CX Auth Token" autoComplete="off" required />
                </div>
                <div className="account-group">
                    <label htmlFor="cx_refresh_token">CX Refresh Token</label>
                    <input type="text" name="cx_refresh_token" id="cx_refresh_token" value={cx_refresh_token} onChange={handleChange} placeholder="CX Refresh Token" autoComplete="off" required />
                </div>
                <div className="account-group">
                    <label htmlFor="gmail_refresh_token">Gmail Refresh Token</label>
                    <input type="text" name="gmail_refresh_token" id="gmail_refresh_token" value={gmail_refresh_token} onChange={handleChange} placeholder="Gmail Refresh Token" autoComplete="off" required />
                </div>
                {/* <div className="account-group">
                    <label htmlFor="tg_watcher_chat_id">TG Watcher Chat ID</label>
                    <input type="text" name="tg_watcher_chat_id" id="tg_watcher_chat_id" value={tg_watcher_chat_id} onChange={handleChange} placeholder="TG Watcher Chat ID" autoComplete="off" required />
                </div> */}
                <div className="account-group">
                    <label htmlFor="tg_alert_chat_id">TG Alert Chat ID</label>
                    <input type="text" name="tg_alert_chat_id" id="tg_alert_chat_id" value={tg_alert_chat_id} onChange={handleChange} placeholder="TG Alert Chat ID" autoComplete="off" required />
                </div>
                {/* <div className="account-group">
                    <label htmlFor="tf_watcher_follow">TF Watcher Follow</label>
                    <input type="text" name="tf_watcher_follow" id="tf_watcher_follow" value={tf_watcher_follow} onChange={handleChange} placeholder="TF Watcher Follow" autoComplete="off" required />
                </div> */}
                <div className="account-group">
                    <label htmlFor="tf_alert_follow">TF Alert Follow</label>
                    <input type="text" name="tf_alert_follow" id="tf_alert_follow" value={tf_alert_follow} onChange={handleChange} placeholder="TF Alert Follow" autoComplete="off" required />
                </div>
                <div className="account-group">
                    <label htmlFor="bnc_api_key">BNC API Key</label>
                    <input type="text" name="bnc_api_key" id="bnc_api_key" value={bnc_api_key} onChange={handleChange} placeholder="BNC API Key" autoComplete="off" required />
                </div>
                <div className="account-group">
                    <label htmlFor="bnc_private_key">BNC Private Key</label>
                    <input type="text" name="bnc_private_key" id="bnc_private_key" value={bnc_private_key} onChange={handleChange} placeholder="BNC Private Key" autoComplete="off" required />
                </div>
                <div className="account-group">
                    <label htmlFor="">Proxy</label>
                    <input type="text" name="proxy" id="proxy" value={proxy} onChange={handleChange} placeholder="Proxy" autoComplete="off" required />
                </div>
                <div className="account-group--cta">
                    <button type="button" className="account__btn account__btn-cancel cta" onClick={handleCancel}>Cancel</button>
                    <button type="submit" className="account__btn cta">{buttontext}</button>
                </div>
            </form>    
        </div>
    )
}

export default FormAccount