import { toast } from "react-toastify"

// Handle toast after sync
const notifyToastSync = () => {
        const msg = localStorage.getItem('toast_msg')
        if(msg) {
            toast.success(msg)
            setTimeout(() => {
                localStorage.removeItem('toast_msg')
            }, 2000)
        }
    }

export default notifyToastSync