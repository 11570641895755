const getAccount = (id) => {
    return fetch(`${process.env.REACT_APP_PORT}/account/${id}`)
        .then(response => {
            return response.json()
        })
        .then(res => {
            return res.data
        })
}

export {getAccount}