import { useEffect } from "react";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { editAccount } from "../../controller/account/editAccount";
import { getAccount } from "../../controller/account/getAccount";
import { toast } from "react-toastify";
import Breadcrumb from "../../components/Breadcrumb";
import Loader from "../../components/Loader";
import FormAccount from "../../components/UI/FormAccount";

const EditAccount = ({cookies, setCookie}) => {
    const {id} = useParams()
    const navigate = useNavigate()
    const [isLoad, setIsLoad] = useState(true)
    const [editAccountData, setEditAccountData] = useState({
        general_username: '',
        cx_email: '',
        cx_password: '',
        cx_auth_token: '',
        cx_refresh_token: '',
        gmail_refresh_token: '',
        tf_watcher_follow: '',
        tf_alert_follow: '',
        tg_watcher_chat_id: '',
        tg_alert_chat_id: '',
        bnc_api_key: '',
        bnc_private_key: '',
        proxy: ''
    })

    const {general_username} = editAccountData

    const handleDataAccount = async () => {
        const data = await getAccount(id)
        setEditAccountData({...data})
        setIsLoad(false)
    }

    const handleChange = (e) => {
        setEditAccountData(preValue => {
            return {
                ...preValue,
                [e.target.name]: e.target.value
            }
        })
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        setIsLoad(true)
        if(cookies.id === id) {
            setCookie('username', general_username, { path: '/' });
        }
        const message = await editAccount(id, editAccountData)
        toast.success(message)
        navigate('/account/switch')
        // handleDataAccount()
    }

    useEffect(() => {
        handleDataAccount()
    }, [])


    return (
        <div className="account edit">
            <Breadcrumb />
            <h1>Edit Account</h1>
            {isLoad ? <Loader /> : 
            <FormAccount handleChange={handleChange} handleSubmit={handleSubmit} buttontext='Update' data={editAccountData} />}
        </div>
    )
}

export default EditAccount