import { useEffect } from "react"
import { useState } from "react"
import { getAllAccount } from "../../controller/account/getAllAccount"
import { deleteAccount } from "../../controller/account/deleteAccount"
import { toast } from "react-toastify"
import Loader from "../../components/Loader"
import Breadcrumb from "../../components/Breadcrumb"
import ModalDelete from "../../components/ModalDelete"

const SwitchAccount = ({cookies, setCookie, removeCookie}) => {
    const [isLoad, setIsLoad] = useState(true)
    const [isPopUp, setIsPopUp] = useState(false)
    const [accounts, setAccounts] = useState([])
    const [currID, setCurrID] = useState(cookies.id || '')
    const [deletedAcc, setDeletedAcc] = useState({id: '', username: ''})
    const {id, username} = deletedAcc

    const handleGetAccount = async () => {
        const data = await getAllAccount()
        setAccounts(data)
        console.log(data);
        setIsLoad(false)
    }

    const handleChange = (e) => {
        const id = e.target.value
        setCurrID(id)
    }

    const handleSwitchAccount = () => {
        if(cookies.id === currID) {
            toast.info('account already switched!')
        } else {
            const {general_username: currUsername} = accounts.find(data => data._id === currID)
            setCookie('id', currID, { path: '/' });
            setCookie('username', currUsername, { path: '/' });
            toast.success('success switch account!')
            handleGetAccount()
        }
    }

    const handleDeleteModal = (id, username) => {
        setIsPopUp(true)
        setDeletedAcc({id, username})
    }

    const handleDeleteAccount = async () => {
        setIsPopUp(false)
        setIsLoad(true)
        if(cookies.id === id) {
            removeCookie('id', {path:'/'})
            removeCookie('username', {path:'/'})
        }
        await deleteAccount(id)
        setDeletedAcc({id: '', username: ''})
    }

    const handleChecked = (id) => {
        return id === currID ? true : ''
    }

    useEffect(() => {
        handleGetAccount()
    }, [])

    return (
        <div className="switch">
            <Breadcrumb />
            {isLoad ? <Loader /> :
                <div className="switch__container">
                    <div className="switch__account">
                        <ul className="switch__header">
                            <li>#</li>
                            <li>Username</li>
                            <li>Action</li>
                        </ul>
                        <div className="switch__account-box">
                            {accounts.map((data, i) => {
                                const {_id, general_username} = data

                                return (
                                    <div className="switch__account-list" key={i+1}>
                                        <input type="radio" name="choose_account" id={`choose_account-${i+1}`} value={_id} className="switch__radio" checked={handleChecked(_id)} onChange={handleChange} hidden />
                                        <label htmlFor={`choose_account-${i+1}`} className="switch__label"></label>
                                        <h1 className="switch__username">{general_username}</h1>
                                        <div className="switch__action">
                                            <a type='button' href={`/account/edit/${_id}`} className="switch__cta switch__cta-edit">Edit</a>
                                            <button type='button' className="switch__cta switch__cta-delete" onClick={() => handleDeleteModal(_id, general_username)}>Delete</button>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                    <button className="switch__btn cta" onClick={handleSwitchAccount}>Switch</button>
                </div>
            }

            <ModalDelete isPopUp={isPopUp} setIsPopUp={setIsPopUp} username={username} handleDeleteAccount={handleDeleteAccount} />
        </div>
    )
}

export default SwitchAccount