import { NavLink } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import bell from "../asset/bell.svg";
import candlestick from "../asset/candlestick.svg";
import chart from "../asset/chart.svg";
import profil from "../asset/profil.svg";
import stats from "../asset/stats.svg";
import trades from "../asset/trades.svg";
import gutter from "../asset/gutter-nav.svg";
import add from "../asset/add.svg";
import switch_icon from "../asset/switch.svg";
import logout_icon from "../asset/logout.svg";
import tradingaccount from "../asset/tradingview.svg";
import history from "../asset/history.svg";
import confirm from "../asset/confirm.svg";

const Navigation = ({ cookies, removeCookie }) => {
	const navigate = useNavigate();

	const handleLogout = () => {
		removeCookie("id", { path: "/" });
		removeCookie("username", { path: "/" });
		toast.success("Success logout");
		navigate("/account/choose");
	};

	return (
		<nav className="navigasi">
			<div className="navigasi__top">
				<div className="navigasi__box-user">
					<h1 className="navigasi__title">Sykrium</h1>
					<img src={profil} alt="" className="navigasi__user" />
					<h2 className="navigasi__username">{cookies.username}</h2>
					{/* <div className="navigasi__log">
                        <p>Login with phone</p>
                        <span>+6282213600395</span>
                    </div> */}
				</div>
				<img src={gutter} alt="" className="navigasi__gutter" />
				<div className="navigasi__box">
					<NavLink
						to="/alert"
						activeclassname="active"
						className="navigasi__link"
						exact="true"
					>
						<div className="navigasi__icon">
							<img src={bell} alt="" />
						</div>
						Alert
					</NavLink>
					<NavLink
						to="/watcher"
						activeclassname="active"
						className="navigasi__link"
						exact="true"
					>
						<div className="navigasi__icon">
							<img src={chart} alt="" />
						</div>
						Watcher
					</NavLink>
					<NavLink
						to="/signaldirection"
						activeclassname="active"
						className="navigasi__link"
						exact="true"
					>
						<div className="navigasi__icon">
							<img
								className="navigasi__icon--history"
								src={history}
								alt=""
							/>
						</div>
						Signal Direction
					</NavLink>
					<NavLink
						to="/logs"
						activeclassname="active"
						className="navigasi__link"
						exact="true"
					>
						<div className="navigasi__icon">
							<img src={candlestick} alt="" />
						</div>
						Logs
					</NavLink>
					<NavLink
						to="/statistics"
						activeclassname="active"
						className="navigasi__link"
						exact="true"
					>
						<div className="navigasi__icon">
							<img src={stats} alt="" />
						</div>
						Statistics
					</NavLink>
					<NavLink
						to="/opentrades"
						activeclassname="active"
						className="navigasi__link"
						exact="true"
					>
						<div className="navigasi__icon">
							<img src={trades} alt="" />
						</div>
						Open Trades
					</NavLink>
					<NavLink
						to="/confirmation"
						activeclassname="active"
						className="navigasi__link"
						exact="true"
					>
						<div className="navigasi__icon">
							<img src={confirm} alt="" />
						</div>
						Confirmation
					</NavLink>
					<NavLink
						to="/tradingview"
						activeclassname="active"
						className="navigasi__link"
						exact="true"
					>
						<div className="navigasi__icon">
							<img src={tradingaccount} alt="" />
						</div>
						Trading View
					</NavLink>
					{/* <NavLink to='/setting' activeclassname="active" className="navigasi__link" exact='true'>
                        <div className="navigasi__icon"><img src={setting} alt="" /></div>
                        Setting
                    </NavLink> */}
				</div>
			</div>

			<div className="navigasi__bottom">
				<a
					className="navigasi__bottom-cta navigasi__link"
					href="/account/add"
				>
					<img src={add} alt="" />
					Add Account
				</a>
				<a
					className="navigasi__bottom-cta navigasi__link"
					href="/account/switch"
				>
					<img src={switch_icon} alt="" />
					Switch Account
				</a>
				<div
					className="navigasi__bottom-cta navigasi__link"
					onClick={handleLogout}
				>
					<img src={logout_icon} alt="" />
					Logout
				</div>
			</div>
		</nav>
	);
};

export default Navigation;
