import React from "react";
import { useNavigate } from "react-router-dom";

const FormTradingview = ({handleChange, handleSubmit, buttontext, data}) => {
    const navigate = useNavigate()
    const {username, cookie, webhook_url} = data

    const handleCancel = () => {
        navigate(-1)
    }

    return (
        <div className="account-box">
            <form className="account-form" onSubmit={handleSubmit}>
                <div className="account-group">
                    <label htmlFor="username">Username</label>
                    <input type="text" name="username" id="username" value={username} onChange={handleChange} placeholder="username" autoComplete="off" required />
                </div>
                <div className="account-group account-group--webhook">
                    <label htmlFor="webhook_url">Webhook URL</label>
                    <input type="text" name="webhook_url" id="webhook_url" value={webhook_url} onChange={handleChange} placeholder="webhook url" autoComplete="off" required />
                </div>
                <div className="account-group account-group--cookie">
                    <label htmlFor="cookie">Cookie</label>
                    <textarea name="cookie" id="cookie" placeholder="cookie" rows="6" onChange={handleChange} autoComplete="off" required>{cookie}</textarea>
                </div>
                <div className="account-group--cta">
                    <button type="button" className="account__btn account__btn-cancel cta" onClick={handleCancel}>Cancel</button>
                    <button type="submit" className="account__btn cta">{buttontext}</button>
                </div>
            </form>    
        </div>
    )
}

export default FormTradingview