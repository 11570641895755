const getLog = (pageNumber, username) => {
    return fetch(`${process.env.REACT_APP_PORT}/histories/all?general_username=${username}&page=${pageNumber}`)
        .then(response => {
            return response.json()
        })
        .then(res => {
            return res.data
        })
}

export {getLog}