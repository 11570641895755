const deleteAccount = (id) => {
    return fetch(`${process.env.REACT_APP_PORT}/account/${id}`, {
            method: 'DELETE'
        })
        .then(response => {
            return response.json()
        })
        .then(res => window.location.reload())
}

export {deleteAccount}