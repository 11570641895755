import React, { useEffect } from "react";
import Breadcrumb from "../components/Breadcrumb";
import Loader from "../components/Loader";
import { useState } from "react";
import { getOpenTrades } from "../controller/opentrades/getOpenTrades";
import RowOpenTrades from "../components/RowOpenTrades";

const OpenTrades = ({cookies}) => {
    const [trades, setTrades] = useState([])
    const [isLoad, setIsLoad] = useState(true)

    // Sorting Data Open Trades Alphabetically
    const handleSortAlphabeticalTrades = (results) => {
        return results.sort((a, b) => a.symbol.localeCompare(b.symbol));
    }

    const handleDataOpenTrades = async () => {
        const data = await getOpenTrades(cookies.username)
        const sortedData = handleSortAlphabeticalTrades(data)
        setTrades(sortedData)
        setIsLoad(false)
    }

    useEffect(() => {
        window.scrollTo(0,0)
        handleDataOpenTrades()
    }, [])
    return (
        <section className="trades section">
            <Breadcrumb />
            {isLoad ? 
                <Loader /> :
                <div className="trades__container section__container">
                    <h1 className="trades__title section__title">Open Trades</h1>
                    <table className="trades__table">
                        <thead>
                            <tr>
                                <th></th>
                                <th>Symbol</th>
                                <th>Markprice</th>
                                <th>Unrealized Profit</th>
                                <th>Liquidation Price</th>
                                <th>Leverage</th>
                                <th>Margin</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                trades.map((item, i) => {
                                    return (
                                        <RowOpenTrades key={i} data={item} />
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </div>
            }
        </section>
    )
}

export default OpenTrades