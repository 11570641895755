const createWatcher = (fileWatcher) => {
    const controller = new AbortController()
    // 30 minutes maximum time / timeout to post data:
    // if the request hasn't finished in 30 minuts, then the request is canceled and a timeout error is thrown.
    const timeoutId = setTimeout(() => controller.abort(), 1800000)

    return fetch(`${process.env.REACT_APP_PORT}/tradingview/watchers/create`, {
            method: 'POST',
            signal: controller.signal, // controller.signal assigned to connects fetch() with the AbortController().
            body: fileWatcher
        })
        .then(response => {
            return response.json()
        })
        .then(res => {
            return res.msg
        })
}

export {createWatcher}