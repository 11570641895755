const createAccount = (data) => {
    return fetch(`${process.env.REACT_APP_PORT}/tradingview/accounts`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json',  'Accept': 'application/json'},
            body: JSON.stringify(data)
        })
        .then(response => {
            return response.json()
        })
        .then(res => {
            return res
        })
}

export {createAccount}