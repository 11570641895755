import React from "react";
import Select from 'react-select';
import { useParams } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
import Breadcrumb from "../components/Breadcrumb";
import { getLog } from "../controller/logs/getLog";
import Loader from "../components/Loader";
import searchIcon from '../asset/search.svg';
// import { cryptoIcon } from "../data/cryptoIcon";
import Pagination from "../components/Pagination";
import dataFilter from "../data/filter";
import dataTimeframe from "../data/timeframe";
import RowLogs from "../components/RowLogs";

const Logs = ({cookies}) => {
    const {pageNumber} = useParams()
    const [isLoad, setIsLoad] = useState(true)
    const [filterState, setFilterState] = useState({type_coin: '', position: 'all', timeframe: ''})
    const [selectPosition, setSelectPosition] = useState(dataFilter[0])
    const [selectTimeframe, setSelectTimeframe] = useState('')
    const [filteredData, setFilteredData] = useState([])
    const [dataLogs, setDataLogs] = useState([])
    const [tempPages, setTempPages] = useState([])
    const [listPages, setListPages] = useState([])
    const [pageAttr, setPageAttr] = useState({startPage: 1, limitPage: 5, endPage: 1})
    const {startPage, limitPage, endPage} = pageAttr
    const {type_coin, position, timeframe} = filterState

    const propsPage = {
        startPage,
        endPage,
        listPages,
        setListPages,
        setTempPages,
        mainPath: 'logs'
    }

    const handleSearch = (e) => {
        setFilterState(preValue => {
            return {
                ...preValue,
                [e.target.name]: e.target.value
            }
        })
    }

    const handleFilter = (selectedState, action) => {
        setFilterState(preValue => {
            return {
                ...preValue,
                [action.name]: selectedState.value
            }
        })
    }

    const handleSubmitFilter = () => {
        handleFilterCondition(dataLogs, type_coin, position, timeframe)
    }

    const handleSelectFilter = () => {
        setSelectPosition(dataFilter.find(item => item.value === position))
        setSelectTimeframe(dataTimeframe.find(item => item.value === timeframe))
    }

    const handleFilterCondition = (results, type_coin, position, timeframe) => {
        if(type_coin && position && timeframe) {
            if(position === 'all') {
                const filtering = results.filter(item => {
                    const coin = item.pair.toLowerCase()
                    return coin.includes(type_coin) && item.timeframe === timeframe
                })
                setFilteredData(filtering)
            } else {
                const filtering = results.filter(item => {
                    const coin = item.pair.toLowerCase()
                    const direction = item.direction.toLowerCase()
                    return coin.includes(type_coin) && direction === position && item.timeframe === timeframe
                })
                setFilteredData(filtering)
            }
        } else if(type_coin && position) {
            const filtering = results.filter(item => {
                const coin = item.pair.toLowerCase()
                const direction = item.direction.toLowerCase()
                if(position === 'all') {
                    return coin.includes(type_coin)
                } else {
                    return coin.includes(type_coin) && direction === position
                }
            })
            setFilteredData(filtering)
        } else if(type_coin && timeframe) {
            const filtering = results.filter(item => {
                const coin = item.pair.toLowerCase()
                return coin.includes(type_coin) && item.timeframe === timeframe
            })
            setFilteredData(filtering)
        } else if(position && timeframe) {
            const filtering = results.filter(item => {
                const direction = item.direction.toLowerCase()
                if(position === 'all') {
                    return item.timeframe === timeframe
                } else {
                    return direction === position && item.timeframe === timeframe
                }
            })
            setFilteredData(filtering)
        } else if(type_coin) {
            const filtering = results.filter(item => {
                const coin = item.pair.toLowerCase()
                return coin.includes(type_coin)
            })
            setFilteredData(filtering)
        } else if(position) {
            if(position === 'all') {
                setFilteredData(results)
            } else {
                const filtering = results.filter(item => {
                    const direction = item.direction.toLowerCase()
                    return direction === position
                })
                setFilteredData(filtering)
            }
        } else if(timeframe) {
            const filtering = results.filter(item => {
                return item.timeframe === timeframe
            })
            setFilteredData(filtering)
        }
    }

    const handlePagination = (val) => {
        if(tempPages.length) {
            setListPages(tempPages)
        } else {
            const pages = []
            const checkLimit = val > limitPage ? limitPage : val
    
            for (let i = 1; i <= checkLimit; i++) {
                pages.push(i)
            }
    
            setPageAttr(preValue => {
                return {...preValue, endPage: val}
            })
            setListPages(pages)
        }
    }

    // Sorting Data Log Alphabetically
    const handleSortAlphabeticalLog = (results) => {
        return results.sort((a, b) => a.pair.localeCompare(b.pair));
    }

    const handleDataLogs = async () => {
        setIsLoad(true)
        const {results, totalPages} = await getLog(pageNumber, cookies.username)
        const sortedResults = handleSortAlphabeticalLog(results)

        handlePagination(totalPages)
        setDataLogs(sortedResults)
        // maintain the data based on the last filter 
        handleFilterCondition(sortedResults, type_coin, position, timeframe)
        setIsLoad(false)
    }

    useEffect(() => {
        window.scrollTo(0,0)
        handleDataLogs()
        handleSelectFilter()
    }, [pageNumber])

    return (
        <section className="log section">
            <Breadcrumb />
            {isLoad ? 
                <Loader /> :
                <div className="log__container section__container">
                    <div className="log__header section__header">
                        <h1 className="log__title section__title">Logs</h1>
                        <div className="log__filter-box">
                            <div className="log__input-box">
                                <img src={searchIcon} alt="" />
                                <input type="text" name='type_coin' placeholder='Type' onChange={handleSearch} value={type_coin} />
                            </div>
                            {/* <Select
                                onChange={handleFilter}
                                options={dataTimeframe}
                                className={'select-input log__input-timeframe'}
                                placeholder='Timeframe'
                                defaultValue={selectTimeframe}
                                name='timeframe'
                                theme={(theme) => ({
                                    ...theme,
                                    colors: {
                                    ...theme.colors,
                                    primary25: '#1F2733'
                                    }
                                })}
                            /> */}
                            <Select
                                onChange={handleFilter}
                                options={dataFilter}
                                className={'select-input log__input-filter'}
                                placeholder='Position'
                                defaultValue={selectPosition}
                                name='position'
                                theme={(theme) => ({
                                    ...theme,
                                    colors: {
                                    ...theme.colors,
                                    primary25: '#1F2733'
                                    }
                                })}
                            />
                            <button className="log__search cta" onClick={handleSubmitFilter}>Search</button>
                        </div>
                    </div>
                    <table className="log__table">
                        <thead>
                            <tr>
                                <th>Pair</th>
                                <th>Direction</th>
                                <th>Profit</th>
                                <th>Close Reason</th>
                                <th>Open Date</th>
                                <th>Close Date</th>
                                <th>Running Time</th>
                                <th>Leverage</th>
                                <th>Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                filteredData.map((item, i) => {
                                    return (
                                        <RowLogs key={i} data={item} />
                                    )
                                })
                            }
                        </tbody>
                    </table>
                    {filteredData.length === 0 && <h1 className='not-found'>Pairs are not found.</h1>}
                </div>
            }
            {!isLoad && <Pagination {...propsPage} data={dataLogs} />}
        </section>
    )
}

export default Logs