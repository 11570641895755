import { useEffect } from "react"
import { useState } from "react"
import { getAllAccount } from "../../controller/tradingview/getAllAccount"
import { deleteAccount } from "../../controller/tradingview/deleteAccount"
import Loader from "../../components/Loader"
import Breadcrumb from "../../components/Breadcrumb"
import ModalDelete from "../../components/ModalDelete"
import { useNavigate } from "react-router-dom"

const Tradingview = () => {
    const navigate = useNavigate()
    const [isLoad, setIsLoad] = useState(true)
    const [isPopUp, setIsPopUp] = useState(false)
    const [accounts, setAccounts] = useState([])
    const [deletedAcc, setDeletedAcc] = useState({_id: '', username: ''})
    const {_id, username} = deletedAcc

    const handleGetAccount = async () => {
        const data = await getAllAccount()
        setAccounts(data)
        setIsLoad(false)
    }

    const handleDeleteModal = (_id, username) => {
        setIsPopUp(true)
        setDeletedAcc({_id, username})
    }

    const handleDeleteAccount = async () => {
        setIsPopUp(false)
        setIsLoad(true)
        await deleteAccount(_id)
    }

    const handleNavigate = () => {
        navigate('/tradingview/add')
    }

    useEffect(() => {
        window.scrollTo(0,0)
        handleGetAccount()
    }, [])

    return (
        <div className="tradingview">
            <Breadcrumb />
            {isLoad ? <Loader /> :
                <div className="tradingview__container">
                    <table className="tradingview__table">
                        <thead>
                            <tr>
                                <th>Id</th>
                                <th>Username</th>
                                <th>Webhook URL</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody className="tradingview__account-box">
                            {accounts.map((data, i) => {
                                const {_id, username, webhook_url} = data

                                return (
                                    <tr className="tradingview__account-list" key={i+1}>
                                        <td className="tradingview__id">{_id}</td>
                                        <td className="tradingview__username">{username}</td>
                                        <td className="tradingview__webhook">{webhook_url}</td>
                                        <td>
                                            <div className="tradingview__action">
                                                <a type='button' href={`/tradingview/edit/${_id}`} className="tradingview__cta tradingview__cta-edit">Edit</a>
                                                <button type='button' className="tradingview__cta tradingview__cta-delete" onClick={() => handleDeleteModal(_id, username)}>Delete</button>
                                            </div>
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                    <button className="tradingview__btn cta" onClick={handleNavigate}>Add New Account</button>
                </div>
            }

            <ModalDelete isPopUp={isPopUp} setIsPopUp={setIsPopUp} username={username} handleDeleteAccount={handleDeleteAccount} />
        </div>
    )
}

export default Tradingview