import React, { useEffect } from "react";
import Breadcrumb from "../components/Breadcrumb";
import Loader from "../components/Loader";
import { toast } from "react-toastify";
import { getStatistic } from "../controller/statistic/getStatistic";
import { useState } from "react";
import moment from 'moment';
import 'moment-timezone';
import Flatpickr from "react-flatpickr";

import "flatpickr/dist/themes/dark.css";

const Statistics = ({cookies}) => {
    const [isLoad, setIsLoad] = useState(true)
    const [selectedDate, setSelectedDate] = useState({
        start_date: moment.tz(moment().add(-1, 'days'), 'Asia/Jakarta').format('YYYY-MM-DD HH:mm:ss'),
        end_date: moment.tz(moment(), 'Asia/Jakarta').format('YYYY-MM-DD HH:mm:ss')
    })
    // moment().add(-1, 'days')._d -> TODAY - 1 DAY
    // moment()._d -> TODAY
    // moment.tz(moment(), 'Asia/Jakarta').format('YYYY-MM-DD HH:mm:ss') -> format date timezone based

    const [stats, setStats] = useState({
        "total_profit": '',
        "total_loss": '',
        "net_pnl": '',
        "winning_days": '',
        "loosing_days": '',
        "break_even_days": '',
        "win_rate": '',
        "average_profit": '',
        "average_loss": '',
        "pnl_ratio": ''
    })
    const {start_date, end_date} = selectedDate
    const {
        total_profit, 
        total_loss, 
        net_pnl, 
        winning_days, 
        loosing_days, 
        break_even_days, 
        win_rate, 
        average_profit, 
        average_loss,
        pnl_ratio
    } = stats

    const handleDataStats = async () => {
        const data = await getStatistic(cookies.username, start_date, end_date)
        setStats(preVal => {
            return {...preVal, ...data}
        })
        toast.success('Success get statistics data')
        setIsLoad(false)
    }

    // Convert date time from flatpickr date changes
    const convertDate = (date) => {
        return moment.tz(date, 'Asia/Jakarta').format('YYYY-MM-DD HH:mm:ss')
    }

    // Handle selected start date
    const handleStartDate = ([date]) => {
        const start = convertDate(date)
        setSelectedDate(preVal => {
            return {
                ...preVal,
                start_date: start
            }
        })
    }

    // Handle selected end date
    const handleEndDate = ([date]) => {
        console.log(date);
        const end = convertDate(date)
        setSelectedDate(preVal => {
            return {
                ...preVal,
                end_date: end
            }
        })
    }

    const handleSearchDate = async () => {
        setIsLoad(true)
        handleDataStats()
    }

    useEffect(() => {
        window.scrollTo(0,0)
        handleDataStats()
    }, [])

    return (
        <section className="stats section">
            <Breadcrumb />
            {isLoad ? 
                <Loader /> :
                <div className="stats__container section__container">
                    <div className="stats__flatpicker">
                        <h1 className="stats__title section__title">Statistics</h1>
                        <div className="stats__sectionDate">
                            <div className="stats__flatpicker-box">
                                <span>Start Date:</span>
                                <Flatpickr        
                                    value={start_date}
                                    options={{
                                        enableTime: true,
                                        enableSeconds: true,
                                        dateFormat: "Y-m-d H:i:s"
                                    }}
                                    className='flatpicker'
                                    onChange={handleStartDate}
                                />
                            </div>
                            <div className="stats__flatpicker-box">
                                <span>End Date:</span>
                                <Flatpickr        
                                    value={end_date}
                                    options={{
                                        enableTime: true,
                                        enableSeconds: true,
                                        dateFormat: "Y-m-d H:i:s"
                                    }}
                                    className='flatpicker'
                                    onChange={handleEndDate}
                                />
                            </div>
                            <button className="cta stats__cta" type="button" onClick={handleSearchDate}>Set</button>
                        </div>
                    </div>
                    <div>
                        <div className="stats__analysis">
                            <div className="stats__detail">
                                <h2>Total Profit</h2>
                                <p>{total_profit}</p>
                            </div>
                            <div className="stats__detail">
                                <h2>Total Loss</h2>
                                <p>{total_loss}</p>
                            </div>
                            <div className="stats__detail">
                                <h2>Net Profit/Loss</h2>
                                <p>{net_pnl}</p>
                            </div>
                            <br />
                            <div className="stats__detail">
                                <h2>Winning Days</h2>
                                <p>{winning_days}</p>
                            </div>
                            <div className="stats__detail">
                                <h2>Losing Days</h2>
                                <p>{loosing_days}</p>
                            </div>
                            <div className="stats__detail">
                                <h2>Win Rate</h2>
                                <p>{win_rate ?? '-'}</p>
                            </div>
                            <br />
                            <div className="stats__detail">
                                <h2>Average Profit</h2>
                                <p>{average_profit ?? '-'}</p>
                            </div>
                            <div className="stats__detail">
                                <h2>Average Loss</h2>
                                <p>{average_loss ?? '-'}</p>
                            </div>
                            <div className="stats__detail">
                                <h2>Profit/Loss Ratio</h2>
                                <p>{pnl_ratio ?? '-'}</p>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </section>
    )
}

export default Statistics