import { cryptoIcon } from "../data/cryptoIcon"

const RowPairs = ({data, id, handleChangePair}) => {
    const {_id, alertId, pair = '', name = '', direction, timeframe, active, enable = '', updatedAt} = data

    return (
        <>
            <tr>
                <td className='pair__coin'><img src={cryptoIcon(pair || name)} alt="crypto coin" loading='lazy' />{pair || name}</td>
                <td className={`pair__position pair__position-${direction.toLowerCase() === 'long' ? 'long' : 'short'}`}>{direction}</td>
                <td>{timeframe}</td>
                {!id.includes('signal-direction') && 
                <td>
                    <span className={`pair__status pair-${active ? 'green' : 'red'}`}>{active ? 'on': 'off'}</span>
                </td>
                }
                {id.includes('alert') && 
                <td>
                    <span className={`pair__status pair-${enable ? 'blue' : 'grey'}`}>{enable ? 'Enable': 'Disable'}</span>
                </td>
                }
                {id.includes('alert') && <td className="pair__updatedAt">{new Date(updatedAt).toLocaleString('en-GB')}</td>}
                {id.includes('alert') &&
                <td className='pair__checkbox'>
                    <input type="checkbox" className='coin-check' name={`coin_${alertId}`} id={id} value={alertId} onChange={handleChangePair} />
                </td>
                }
                {id.includes('signal-direction') &&
                <td className='pair__checkbox'>
                    <input type="checkbox" className='coin-check' name={`coin_${_id}`} id={id} value={`${_id},${direction}`} onChange={handleChangePair} />
                </td>
                }
                {/* <td className='watcher__action'>
                <button type='button' className="watcher__cta watcher__cta-active">Active</button>
                <button type='button' className="watcher__cta watcher__cta-delete">Delete</button>
                </td> */}
            </tr>
        </>
    )
}

export default RowPairs