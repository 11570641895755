import { useEffect } from "react";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getAccount } from "../../controller/tradingview/getAccount";
import { editAccount } from "../../controller/tradingview/editAccount";
import { toast } from "react-toastify";
import Breadcrumb from "../../components/Breadcrumb";
import Loader from "../../components/Loader";
import FormTradingview from "../../components/UI/FormTradingview";

const TradingviewEdit = () => {
    const {id} = useParams()
    const navigate = useNavigate()
    const [isLoad, setIsLoad] = useState(true)
    const [editAccountData, setEditAccountData] = useState({
        username: '',
        cookie: '',
        webhook_url: ''
    })

    const handleChange = (e) => {
        setEditAccountData(preValue => {
            return {
                ...preValue,
                [e.target.name]: e.target.value
            }
        })
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        setIsLoad(true)
        const message = await editAccount(id, editAccountData)
        toast.success(message)
        navigate('/tradingview')
    }

    const handleDataAccount = async () => {
        const data = await getAccount(id)
        setEditAccountData({...data[0]})
        setIsLoad(false)
    }

    useEffect(() => {
        handleDataAccount()
    }, [])

    return (
        <div className="account add">
            <Breadcrumb />
            <h1>Edit Tradingview Account</h1>
            {isLoad ? <Loader /> : 
            <FormTradingview handleChange={handleChange} handleSubmit={handleSubmit} buttontext='Update' data={editAccountData} />}
        </div>
    )
}

export default TradingviewEdit